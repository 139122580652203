// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
  500: white,
  contrast: (
    500: $dark-primary-text,
  ),
);

$fuse-black: (
  500: black,
  contrast: (
    500: $light-primary-text,
  ),
);

$fuse-navy: (
  50: #ececee,
  100: #c5c6cb,
  200: #9ea1a9,
  300: #7d818c,
  400: #5c616f,
  500: #3c4252,
  600: #353a48,
  700: #2d323e,
  800: #262933,
  900: #1e2129,
  A100: #c5c6cb,
  A200: #9ea1a9,
  A400: #5c616f,
  A700: #2d323e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$green: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #4caf50,
  600: #3cd184,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$light-blue: (
  50: #e1f5fe,
  100: #b3e5fc,
  200: #81d4fa,
  300: #4fc3f7,
  400: #29b6f6,
  500: #8ed3f3,
  600: #039be5,
  700: #0288d1,
  800: #0277bd,
  900: #01579b,
  A100: #80d8ff,
  A200: #40c4ff,
  A400: #00b0ff,
  A700: #0091ea,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
  'green': $green,
  light-blue: $light-blue,
  fuse-white: $fuse-white,
  fuse-black: $fuse-black,
  fuse-navy: $fuse-navy,
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-typography-config(
  $font-family: 'Muli, Helvetica Neue, Arial, sans-serif',
  $title: mat.define-typography-level(20px, 32px, 600),
  $body-2: mat.define-typography-level(14px, 24px, 600),
  $button: mat.define-typography-level(14px, 14px, 600),
  $input: mat.define-typography-level(16px, 1.5, 400),
);

// Setup the typography
@include mat.all-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import './layouts/components/chat-panel/chat-panel.theme';
@import './layouts/components/toolbar/toolbar.theme';
@import './layouts/auth/auth.theme';

@mixin e-commerce-products-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .e-commerce-products {
    .top-bg {
      @include media-breakpoint('lt-md') {
        height: 256px;
      }
    }

    > .center {
      > .header {
        .search-wrapper {
          width: 100%;
          max-width: 480px;
          border-radius: 28px;
          margin-left: 100px;
          overflow: hidden;
          @include mat.elevation(1);

          @include media-breakpoint('xs') {
            width: 100%;
          }

          .search {
            width: 100%;
            height: 48px;
            line-height: 48px;
            padding: 0 18px;

            input {
              width: 100%;
              height: 48px;
              min-height: 48px;
              max-height: 48px;
              padding: 0 16px;
              border: none;
              outline: none;
            }
          }
        }

        @include media-breakpoint('lt-md') {
          padding: 8px 0;
          height: 192px !important;
          min-height: 192px !important;
          max-height: 192px !important;
        }
      }

      > .content-card {
        position: relative !important;
        padding-bottom: 0 !important;

        .loading-shade {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 56px;
          background: rgba(0, 0, 0, 0.15);
          z-index: 1000;
        }
      }
    }
    .header {
      .search-wrapper {
        background: map-get($background, card);

        .search {
          .mat-icon {
            color: map-get($foreground, icon);
          }

          input {
            background: map-get($background, card);
            color: map-get($foreground, text);
          }
        }
      }
    }

    .period-start,
    .period-end {
      mat-datepicker-toggle {
        display: inline-block;
      }
    }

    .filters-wrapper {
      .mat-form-field-appearance-outline {
        .mat-form-field-wrapper {
          padding-bottom: 0;
          margin: 0;
          min-width: 200px;
        }

        .mat-form-field-infix {
          padding: 0.75em 0;
          border-top: 0.84375em solid transparent;
        }
      }
    }

    .mat-header-row {
      min-height: 64px;
    }

    .mat-row {
      min-height: 84px;
    }

    .mat-paginator-container {
      height: 70px;
    }

    .products-table {
      flex: 1 1 auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    .table-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 90px);
      position: relative;

      .mat-paginator {
        border: 1px solid #f4f4f4;

        .mat-form-field-flex {
          min-width: auto;
        }
      }

      .img-thumb {
        height: 46px;
        width: calc(46px * (16 / 9));
        overflow: hidden;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 100%;
        }
      }
    }
  }

  .hidden {
    display: none !important;
  }

  .w-75 {
    max-width: 75px;
  }

  .w-100 {
    max-width: 100px;
  }

  .info-line .title {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 4px;
  }

  .job {
    .company {
      padding: 0 16px 0 0;
      font-weight: 600;
    }

    .date {
      padding: 0 24px 0 0;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .header {
    .subtitle {
      margin: 6px 0 0 0;
    }

    .logo {
      & .logo-icon:first-child {
        cursor: pointer;
      }

      & .logo-subtitle {
        display: block;
        font-size: 16px;
        font-weight: normal;
        transform: translateY(-3px);
        opacity: 0.7;
      }
    }
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 0.954em 0;
    }

    .mat-select-arrow-wrapper {
      display: inline-flex;
    }
  }

  .mat-form-field-disabled {
    .mat-input-element {
      color: #a5a5a5;
    }
  }

  .mat-form-field-infix {
    border-top: 0.84375em solid transparent;
  }

  input.mat-input-element {
    margin-top: -0.0625em;
  }

  .divider--title {
    margin-bottom: 24px;

    > span {
      font-size: 16px;
      font-weight: 600;
      transform: translateY(-1px);
    }

    > .line {
      flex-grow: 1;
      margin-left: 30px;
      height: 1px;
      background: #d3d4d8;
    }
  }

  .mat-dialog-container {
    padding: 0;
    min-width: 40%;

    .mat-raised-button {
      max-width: 200px;
      min-width: 100px;
      height: 48px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .table.table-head-fixed thead tr:nth-child(1) th {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .table-responsive {
    overflow-x: auto;
  }
}

.box-shadow {
  box-shadow: -1px 4px 20px -5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -1px 4px 20px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -1px 4px 20px -5px rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none !important;
}

// Define a mixin for easier access
@mixin components-theme($theme) {
  // Layout components
  @include chat-panel-theme($theme);
  @include toolbar-theme($theme);

  // Login Page
  @include auth-theme($theme);
  @include e-commerce-products-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$theme: mat.define-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
//noinspection ALL
body.theme-default {
  // Create an Angular Material theme from the $theme map
  @include mat.all-component-themes($theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$yellow-light-theme: mat.define-light-theme(
  $yellow-light-theme-primary-palette,
  $yellow-light-theme-accent-palette,
  $yellow-light-theme-warn-palette
);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
  // Generate the Angular Material theme
  @include mat.all-component-themes($yellow-light-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($yellow-light-theme);

  // Apply the theme to the user components
  @include components-theme($yellow-light-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $yellow-light-theme-primary-palette,
    accent: $yellow-light-theme-accent-palette,
    warn: $yellow-light-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$blue-gray-dark-theme: mat.define-dark-theme(
  $blue-gray-dark-theme-primary-palette,
  $blue-gray-dark-theme-accent-palette,
  $blue-gray-dark-theme-warn-palette
);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
  // Generate the Angular Material theme
  @include mat.all-component-themes($blue-gray-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($blue-gray-dark-theme);

  // Apply the theme to the user components
  @include components-theme($blue-gray-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $blue-gray-dark-theme-primary-palette,
    accent: $blue-gray-dark-theme-accent-palette,
    warn: $blue-gray-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme(
  $pink-dark-theme-primary-palette,
  $pink-dark-theme-accent-palette,
  $pink-dark-theme-warn-palette
);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
  // Generate the Angular Material theme
  @include mat.all-component-themes($pink-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($pink-dark-theme);

  // Apply the theme to the user components
  @include components-theme($pink-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}
