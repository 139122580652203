.description-card {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 8px 42px;
  min-width: 400px;
  height: 100%;

  &-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  table {
    &.description-card-table {
      width: 100%;
      border-spacing: 0;
      tr {
        td {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          padding-bottom: 16px;
          text-align: left;
          vertical-align: top;

          &:first-child {
            white-space: nowrap;
            min-width: 120px;
          }

          &:last-child {
            font-weight: 700;
            text-align: right;
          }
        }
      }
    }
  }
}
