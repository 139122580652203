.badge {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;
  border-radius: 100px;
  color: white;

  &-green {
    background-color: #3cd184;
  }

  &-blue {
    background-color: #8ed3f3;
  }

  &-grey {
    background-color: #c4c4c4;
  }

  &-red {
    background-color: #f74048;
  }

  &-yellow {
    background-color: #ffcb21;
  }
}
