// Import Quill
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

// Import Fuse core library
@import 'projects/@fuse/scss/core';
// Import app.theme.scss
@import 'app/app.theme';
@import 'projects/@shared/snackbar.theme';
@import 'assets/scss/description-card';
@import 'projects/@shared/components/text-badge/badge';
@import './assets/scss/mat-table';
