@use '@angular/material' as mat;
@mixin auth-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);
  #salon-auth {
    #auth-intro {
      color: white;
    }

    #auth-form-wrapper {
      @if ($is-dark) {
        background: mat.get-color-from-palette($fuse-navy, 600);
      } @else {
        background: map-get($background, card);
      }
      .title {
        font-size: 21px;
        text-align: center;
      }
    }
  }
}
